import React, { useContext } from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ContentMasonry from "../components/content/contentMasonry"
import ContentList from "../components/content/contentList"
import LayoutContext from "../state/context/layout-context"
import ContentListToggleIcons from "../components/content/contentListToggleIcons"
import ContentListFooterLinks from "../components/content/contentListFooterLinks"

export default ({ data, location }) => {
  const {
    contentfulAuthor: { name, shortDescription, image, slug, canonical },
    allContent: { edges: content },
  } = data

  const { layoutType, toggleLayout } = useContext(LayoutContext)

  return (
    <Layout location={location} pageTitle={name}>
      <SEO
        title={name}
        description={shortDescription}
        pathname={`author/${slug}`}
        canonical={`author/${canonical}`}
      />
      <div className="container mx-auto px-6">
        <div>
          <h1 className="text-4xl mt-6 mb-10 md:text-5xl">{name}</h1>
          <div className="container mb-10 flex flex-col mx-auto md:flex-row">
            <div className="w-full md:w-1/5 md:m-8">
              <Img
                className="mx-auto rounded-full w-32 mb-10 md:mb-0"
                fluid={image.fluid}
                alt={image.description}
              />
            </div>
            <div className="flex flex-col items-center justify-center md:w-4/5">
              <p className="text-xl font-serif inline-block mb-4 max-w-md md:max-w-full">
                {shortDescription}
              </p>
            </div>
          </div>
        </div>
        <div>
          <div className="container mx-auto px-6">
            <div className="flex justify-between items-center">
              <h2 className="text-4xl font-bold mt-6 mb-10 md:text-4xl">
                Content
              </h2>
              <ContentListToggleIcons
                layoutType={layoutType}
                toggleLayout={toggleLayout}
              />
            </div>
            <ContentMasonry layoutType={layoutType} content={content} />
            <ContentList layoutType={layoutType} content={content} />
            <ContentListFooterLinks />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const authorPageQuery = graphql`
  query($authorId: String) {
    contentfulAuthor(id: { eq: $authorId }) {
      id
      name
      slug
      canonical
      shortDescription
      image {
        id
        fluid(maxWidth: 580) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
        description
      }
    }
    allContent: allContentfulLayoutTemplate(
      filter: {
        type: { in: ["Article", "Post"] }
        author: { id: { eq: $authorId } }
      }
      sort: { fields: published, order: DESC }
    ) {
      totalCount
      edges {
        node {
          id
          title
          type
          slug
          canonical
          published(formatString: "MMM D, YYYY")
          author {
            name
            slug
            image {
              id
              fluid(maxWidth: 580) {
                base64
                aspectRatio
                src
                srcSet
                srcWebp
                srcSetWebp
                sizes
              }
              description
            }
          }
          image {
            id
            fluid(maxWidth: 580) {
              base64
              aspectRatio
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
            }
            description
          }
          shortDescription
        }
      }
    }
  }
`
